export const skills = [
  "React",
  "Vue",
  "Node",
  "Nest",
  "Golang",
  "Python",
  "Java",
  "JavaScript",
  "TypeScript",
  "C",
  "C++",
  "C#",
  "PHP",
  "HTML",
  "CSS",
  "SQL",
  "MongoDB",
  "Git",
];
